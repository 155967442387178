@import '../../styles/variables.scss';

.Record {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 0;
  max-width: 1178px;
  margin: 0 auto;

  @include breakpoint(mobile) {
    padding-top: calc(var(--spacing-4) * 3);
    padding-bottom: 36px;
  }

  @include breakpoint(tablet) {
    padding-top: calc(var(--spacing-4) * 3);
    padding-bottom: 36px;
    margin: 0 var(--spacing-2);
  }

  .content {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      color: var(--white);
      @include Font(28px, 40px);
      margin: 0 0 var(--spacing-8);
    }

    .wrapper {
      display: flex;
      align-items: flex-start;

      @include breakpoint(mobile) {
        flex-direction: column;
      }

      @include breakpoint(tablet) {
        flex-direction: row;
        justify-content: space-around;
      }

      @include breakpoint(desktop) {
        flex-direction: row;
        justify-content: space-around;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      text-align: center;

      @include breakpoint(mobile) {
        width: 100%;
        padding: 0 var(--spacing-6);
        margin-bottom: var(--spacing-8);
      }

      @include breakpoint(tablet) {
        height: 200px;
        width: 30%;
      }

      @include breakpoint(desktop) {
        height: 200px;
        width: 30%;
      }

      p {
        @include Font(50px, 60px);
        margin-bottom: var(--spacing-2);
        color: var(--white);
        font-weight: 700;

        span {
          @include Font(50px, 60px);
          margin-bottom: var(--spacing-2);
          color: var(--white);
          font-weight: 700;
          display: inline-block;
        }
      }

      span {
        @include Font(18px, 20px);
        display: block;
        color: var(--whiteSecondary);
      }
    }
  }

  .line-wrapper {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-image: radial-gradient(75% 80% at 50% 50%,
        rgba(122, 44, 191, 0.23) 0%,
        transparent 66%);

    @include breakpoint(mobile) {
      justify-content: space-between;
      padding: var(--spacing-4);
    }

    @include breakpoint(tablet) {
      justify-content: space-between;
      padding: var(--spacing-4);
    }

    @include breakpoint(desktop) {
      justify-content: space-evenly;
    }

    .line {
      width: 1px;
      height: 100%;

      @include breakpoint(mobile) {
        background-image: linear-gradient(0deg,
            transparent 0%,
            rgba(200, 200, 200, 0.2) 50%,
            transparent 100%);
      }

      @include breakpoint(tablet) {
        background-image: linear-gradient(0deg,
            transparent 15%,
            rgba(200, 200, 200, 0.2) 50%,
            transparent 85%);
      }

      @include breakpoint(desktop) {
        background-image: linear-gradient(0deg,
            transparent 15%,
            rgba(200, 200, 200, 0.2) 50%,
            transparent 85%);
      }
    }
  }
}