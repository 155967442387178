@import '../../styles/variables.scss';

.support {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 166px 0 50px;

  @include breakpoint(mobile) {
    padding: 113px 0 50px;
  }

  @include breakpoint(tablet) {
    padding: 113px 0 50px;
  }

  h2 {
    @include Font(28px, 40px);
    font-weight: 700;
    color: var(--white);
    text-align: center;
    position: relative;
    z-index: 10;

    @include breakpoint(mobile) {
      margin: var(--spacing-6) var(--spacing-8) var(--spacing-6);
    }
    @include breakpoint(tablet) {
      margin: var(--spacing-6) var(--spacing-8) var(--spacing-6);
    }
    @include breakpoint(desktop) {
      margin-bottom: calc(var(--spacing-6) * 2);
    }
  }

  .drawer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .top {
    padding-left: 127px;
  }
  .bottom {
    padding-right: 127px;
  }
  .top,
  .bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;

    @include breakpoint(mobile) {
      width: 100%;
      max-width: auto;
      margin-inline: auto;
      height: 75px;
      position: relative;
      overflow: hidden;
      gap: var(--spacing-3);
      margin-bottom: var(--spacing-3);
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0)
      );
    }

    .box {
      width: 270px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid transparent;
      cursor: pointer;
      background-color: #ffffff0d;
      user-select: none;
      transition: border-color 0.4s ease;

      @include breakpoint(mobile) {
        width: 200px;
        height: 75px;
        position: absolute;
        animation-timing-function: linear;
        animation-duration: 30s;
        animation-iteration-count: infinite;
      }
      @include breakpoint(tablet) {
        width: 200px;
        height: 75px;
      }

      &:hover {
        border-color: var(--primary);
      }
      img {
        @include breakpoint(mobile) {
          width: 80%;
        }
        @include breakpoint(tablet) {
          width: 80%;
        }
      }

      .svg {
        width: 137px;
      }
    }
    .left {
      left: max(calc(165px * 4), 100%);
      animation-name: scrollLeft;
      &:nth-child(1) {
        animation-delay: calc(30s / 4 * (4 - 1) * -1);
      }
      &:nth-child(2) {
        animation-delay: calc(30s / 4 * (4 - 2) * -1);
      }
      &:nth-child(3) {
        animation-delay: calc(30s / 4 * (4 - 3) * -1);
      }
      &:nth-child(4) {
        animation-delay: calc(30s / 4 * (4 - 4) * -1);
      }
    }

    .right {
      right: max(calc(165px * 4), 100%);
      animation-name: scrollRight;

      &:nth-child(1) {
        animation-delay: calc(30s / 4 * (4 - 1) * -1);
      }
      &:nth-child(2) {
        animation-delay: calc(30s / 4 * (4 - 2) * -1);
      }
      &:nth-child(3) {
        animation-delay: calc(30s / 4 * (4 - 3) * -1);
      }
      &:nth-child(4) {
        animation-delay: calc(30s / 4 * (4 - 4) * -1);
      }
    }
  }

  .light-1 {
    width: 1010px;
    height: 546px;
    border-radius: 50%;
    background: rgba(123, 44, 191, 0.25);
    position: absolute;
    top: -200px;
    left: 50%;
    filter: blur(150px);
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;

    @include breakpoint(mobile) {
      width: 706px;
      height: 483px;
      top: -180px;
      background: rgba(123, 44, 191, 0.30);
    }
  }
}
@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

@keyframes scrollRight {
  to {
    right: -200px;
  }
}
