@import '../../styles/variables.scss';

.about {
  margin-top: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint(mobile) {
    padding: 0 var(--spacing-4);
    margin-top: 46px;
  }

  @include breakpoint(tablet) {
    padding: 0 var(--spacing-4);
    margin-top: 46px;
  }

  @include breakpoint(desktop) {}

  .head {
    @include Font(28px, 40px);
    text-align: center;
    font-weight: 700;
    color: var(--white);
    position: relative;
    z-index: 10;

    @include breakpoint(mobile) {
      margin-bottom: 47px;
    }

    @include breakpoint(tablet) {
      margin-bottom: 47px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 80px;
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    gap: var(--spacing-4);

    @include breakpoint(mobile) {
      flex-direction: column-reverse;
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    @include breakpoint(desktop) {
      flex-direction: row;
    }

    .box {
      position: relative;
      border-radius: var(--spacing-4);
      border: 2px solid rgba(255, 255, 255, 0.1);
      z-index: 10;
      box-shadow: 0px 4px 4px rgb(34, 8, 58, 0);
      transition: box-shadow 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 1.5s ease;
            
      &:hover {
        box-shadow: 0px 4px 4px rgb(34, 8, 58, 0.6);
        border-color: var(--primary);
      }

      @include breakpoint(mobile) {
        margin-bottom: var(--spacing-2);
        padding: var(--spacing-6);
        max-width: 444px;
      }

      @include breakpoint(tablet) {
        max-width: 444px;
        padding: var(--spacing-6);
      }

      @include breakpoint(desktop) {
        max-width: 444px;
        padding: 64px 52px;
      }

      .image {
        position: absolute;
      }

      .title {
        color: var(--white);
        font-weight: 700;
        line-height: 122.5%;
        max-width: 300px;

        @include Font(26px, 32px);

        @include breakpoint(mobile) {
          margin-bottom: var(--spacing-2);
        }

        @include breakpoint(tablet) {
          margin-bottom: var(--spacing-2);
        }

        @include breakpoint(desktop) {
          margin-bottom: var(--spacing-3);
        }
      }

      .description {
        @include Font(18px, 20px);
        color: rgba(255, 255, 255, 0.3);
        line-height: 122.5%;
      }
    }

    .first {
      flex: 1;
      @include breakpoint(mobile) {
        background-image: linear-gradient(150deg,
            rgba(122, 44, 191, 0.9) 0%,
            transparent 50%);
        background-repeat: no-repeat;
      }

      @include breakpoint(tablet) {
        background-image: linear-gradient(150deg,
            rgba(122, 44, 191, 0.9) 0%,
            transparent 50%);
        background-repeat: no-repeat;
      }

      .image {
        width: 255px;

        @include breakpoint(mobile) {
          bottom: -90px;
          left: -25px;
          width: 180px;
        }

        @include breakpoint(tablet) {
          bottom: -90px;
          left: -25px;
          width: 180px;
        }

        @include breakpoint(desktop) {
          bottom: -70px;
          left: -140px;
        }
      }
    }

    .second {
      flex: 1;
      @include breakpoint(desktop) {
        background-image: linear-gradient(150deg,
            rgba(122, 44, 191, 0.9) 0%,
            transparent 50%);
        background-repeat: no-repeat;
      }

      .image {
        width: 293px;

        @include breakpoint(mobile) {
          top: -90px;
          right: -50px;
          width: 170px;
        }

        @include breakpoint(tablet) {
          top: -90px;
          right: -50px;
          width: 170px;
        }

        @include breakpoint(desktop) {
          top: -175px;
          right: -140px;
        }
      }
    }
  }

  .light-1 {
    width: 1240px;
    height: 849px;
    border-radius: 50%;
    background: rgba(123, 44, 191, 0.25);
    position: absolute;
    top: -360px;
    left: 50%;
    filter: blur(150px);
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;

    @include breakpoint(mobile) {
      width: 706px;
      height: 483px;
      top: -30px;
      background: rgba(123, 44, 191, 0.30);
    }
  }
}