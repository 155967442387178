.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.modal-content {
  background: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  padding: var(--spacing-8) calc(var(--spacing-6) * 2)
    calc(var(--spacing-5) * 2);
}

.modal-close {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
