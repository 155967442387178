@import '../../styles/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;

  @include breakpoint(mobile) {
    padding: var(--spacing-6) var(--spacing-4) calc(var(--spacing-7) * 2);
    height: auto;
  }

  @include breakpoint(tablet) {
    padding: var(--spacing-6) var(--spacing-4) calc(var(--spacing-7) * 2);
    height: auto;
  }

  @include breakpoint(desktop) {
    padding: 0 10% calc(var(--spacing-7) * 2);
  }

  h2 {
    @include Font(28px, 40px);
    margin-bottom: 74px;
    position: relative;
    z-index: 10;

    @include breakpoint(mobile) {
      margin-bottom: var(--spacing-6);
      text-align: center;
    }

    @include breakpoint(tablet) {
      margin-bottom: var(--spacing-6);
      text-align: center;
    }
  }

  .wrapper {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr;


    @include breakpoint(mobile) {
      max-width: 390px;
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
      grid-gap: var(--spacing-6);
      background-image: radial-gradient(75% 80% at 50% 50%,
          rgba(122, 44, 191, 0.23) 0%,
          transparent 66%);
    }

    @include breakpoint(tablet) {
      grid-template-columns: auto auto;
      grid-template-rows: auto 1fr;
      grid-gap: var(--spacing-4);
    }

    @include breakpoint(desktop) {
      grid-template-columns: auto auto;
      grid-template-rows: auto 1fr;
      grid-gap: var(--spacing-4);
    }

    .features {
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: var(--spacing-4);
      position: relative;
      overflow: hidden;
      position: relative;
      z-index: 10;
      box-shadow: 0px 4px 4px rgb(34, 8, 58, 0);
      transition: box-shadow 0.4s ease-in-out, border-color 0.4s ease-in-out, transform 1.5s ease;
      backdrop-filter: blur(20px);

      &:hover {
        box-shadow: 0px 4px 4px rgb(34, 8, 58, 0.6);
        border-color: var(--primary);
      }

      .image {
        z-index: 1;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 4;

        .title {
          @include Font(26px, 32px);
          color: var(--white);
          font-weight: 600;
          margin-bottom: 14px;
          line-height: 122.5%;
        }

        .description {
          @include Font(18px, 20px);
          color: rgba($color: #ffffff, $alpha: 0.3);
          font-weight: 400;
          margin-bottom: var(--spacing-4);
          line-height: 122.5%;
        }
      }

      .button {
        @include Font(16px, 18px);
      }
    }

    .first {
      display: flex;
      padding: 76px 40px;

      @include breakpoint(desktop) {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        max-width: 907px;
        height: 320px;
      }

      @include breakpoint(tablet) {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        width: 100%;
        height: 320px;
      }

      @include breakpoint(mobile) {
        height: 610px;
        padding: 40px 28px;
      }

      .content-wrapper {
        align-self: center;
        width: 50%;

        @include breakpoint(mobile) {
          width: 100%;
          align-self: flex-start;
        }
      }

      .image {
        position: absolute;

        @include breakpoint(desktop) {
          top: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          object-fit: cover;
        }

        @include breakpoint(tablet) {
          top: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          object-fit: cover;
        }

        @include breakpoint(mobile) {
          bottom: 0;
          right: 0;
          width: 100%;
        }
      }
    }

    .second {
      padding: 40px;
      height: 610px;
      display: flex;

      @include breakpoint(desktop) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        width: 444px;
      }

      @include breakpoint(mobile) {
        padding: 40px 28px;
        height: 540px;
      }

      @include breakpoint(tablet) {
        height: 540px;
      }

      .content-wrapper {
        align-self: flex-end;
      }

      .image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
      }
    }

    .third {
      padding: 40px;
      height: 610px;
      display: flex;
      align-self: center;

      @include breakpoint(desktop) {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        width: 444px;
      }

      @include breakpoint(mobile) {
        padding: 40px 28px;
        height: 540px;
      }

      @include breakpoint(tablet) {
        height: 540px;
      }

      .image {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .light-1 {
    width: 594px;
    height: 399px;
    border-radius: 50%;
    background: rgba(123, 44, 191, 0.5);
    position: absolute;
    top: 20px;
    right: -150px;
    filter: blur(150px);
    pointer-events: none;
    z-index: 0;

    @include breakpoint(mobile) {
      width: 403px;
      height: 253px;
      top: 450px;
    }
  }

  .light-2 {
    width: 594px;
    height: 399px;
    border-radius: 50%;
    background: rgba(123, 44, 191, 0.30);
    position: absolute;
    top: 290px;
    left: -60px;
    filter: blur(150px);
    pointer-events: none;
    z-index: 0;

    @include breakpoint(mobile) {
      display: none;
    }
  }

  .light-3 {
    width: 594px;
    height: 399px;
    border-radius: 50%;
    background: rgba(123, 44, 191, 0.5);
    position: absolute;
    top: 610px;
    right: 10px;
    filter: blur(150px);
    pointer-events: none;
    z-index: 0;

    @include breakpoint(mobile) {
      width: 406px;
      height: 273px;
      top: 1540px;
      right: 140px;
    }
  }
}