@import '/src/client/styles/variables';

.App {
  background-color: var(--backgroundColor);
  color: var(--white);
  overflow: hidden;
  max-width: 2100px;
  margin: 0 auto;
  position: relative;
}
