@import '../../styles/variables.scss';

.hero {
  background-image: radial-gradient(100% 80% at -10% 50%,
      rgba(123, 44, 191, 0.23) 0%,
      transparent 66%);

  .wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1178px;
    margin: 0 auto;
    padding-left: 103px;
    padding-top: 167px;
    padding-bottom: 122px;

    @include breakpoint(tablet) {
      padding-left: 60px;
      padding-top: 64px;
      padding-bottom: 64px;
    }

    @include breakpoint(mobile) {
      padding-left: 20px;
      padding-top: 330px;
      padding-bottom: 32px;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 510px;
      
      .content-title {
        @include Font(40px, 60px);
        color: var(--white);
        font-weight: 700;
        white-space: nowrap;
        line-height: 122.5%;

        .content-title-primary {
          display: inline-block;
          font-size: inherit;
          color: var(--primary);
          background: linear-gradient(96deg,
              #b764ff 54.32%,
              var(--primary) 87.52%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;

          /* @include breakpoint(mobile) {
            margin-left: var(--spacing-3);
          }

          @include breakpoint(desktop) {
            margin-left: var(--spacing-4);
          }

          @include breakpoint(tablet) {
            margin-left: var(--spacing-3);
          } */
        }
      }

      .content-description {
        color: var(--whiteSecondary);
        @include Font(15px, 20px);
        line-height: 122.5%;

        @include breakpoint(mobile) {
          margin-top: var(--spacing-3);
        }

        @include breakpoint(desktop) {
          margin-top: var(--spacing-4);
        }

        @include breakpoint(tablet) {
          margin-top: var(--spacing-3);
        }
      }

      .button {
        width: fit-content;
        @include Font(15px, 20px);

        @include breakpoint(mobile) {
          margin-top: var(--spacing-5);
        }

        @include breakpoint(desktop) {
          margin-top: var(--spacing-7);
        }

        @include breakpoint(tablet) {
          margin-top: var(--spacing-5);
        }
      }
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 1440px;
    
    @media (max-width: 1218px) {
      width: 970px;
    }

    @include breakpoint(tablet) {
      width: 800px;
    }

    @include breakpoint(mobile) {
      width: 730px;
    }

    img {
      width: 100%;
    }
  }
}