@import '../../styles/variables.scss';

.header {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  width: 100%;
  max-width: 1178px;
  position: relative;

  @media (max-width: 1218px) {
    padding: 30px 20px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: baseline;

    @include breakpoint(mobile) {
      display: none;
    }

    @include breakpoint(tablet) {
      display: none;
    }

    img {
      height: 60px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .nav {
    width: 25%;

    @include breakpoint(mobile) {
      display: none;
    }

    @include breakpoint(tablet) {
      display: none;
    }

    .list {
      display: flex;
      list-style: none;
      gap: calc(var(--spacing-8) * 2);
      justify-content: space-between;
    }

    .item {
      color: var(--white);
      cursor: pointer;

      &:last-child {
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        img {
          margin-left: var(--spacing-2);
        }
      }

      @include breakpoint(desktop) {
        &.open {
          .dropdownWrapper .dropdown {
            display: flex;
          }

          .arrow {
            transform: rotate(180deg);
          }
        }

        .arrow {
          transition: transform 0.3s ease-in-out;
        }

        .dropdownWrapper {
          position: absolute;
          top: 100%;
          left: 0;
          padding-top: 20px;
          
          .dropdown {
            display: none;
            overflow: hidden;
            width: 222px;
            flex-direction: column;
            background-color: var(--backgroundColor);
            border-radius: var(--spacing-4);
            border: 1.5px solid rgba(255, 255, 255, 0.2);
            padding: var(--spacing-2) var(--spacing-5);
            transition: opacity 0.3s ease-in-out;

            li {
              list-style: none;

              a {
                display: flex;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: var(--spacing-3) 0;
                color: var(--white);

                &:hover {
                  color: var(--primary);

                  svg {
                    color: var(--primary);
                  }
                }

                svg {
                  color: var(--white);
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu {
    @include breakpoint(mobile) {
      user-select: none;
      width: 24px;
      height: 24px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    @include breakpoint(desktop) {
      display: none;
    }
  }

  .button {
    @include Font(14px, 16px);
  }
}

.modal-content {
  padding: calc(var(--spacing-6) * 2) 0;

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-3);

    img {
      height: 60px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .nav {
    width: 100%;

    .list {
      display: flex;
      list-style: none;
      justify-content: space-between;
      flex-direction: column;
    }

    .item {
      color: var(--white);
      cursor: pointer;
      padding: var(--spacing-4) 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 20px;

      span,
      a {
        font-size: inherit;
      }

      &:last-child {
        display: flex;
        flex-wrap: nowrap;
        border-bottom: none;
        position: relative;

        &.open {
          .dropdown {
            opacity: 1;
          }

          .arrow {
            transform: rotate(180deg);
          }
        }

        .arrow {
          transition: transform 0.3s ease-in-out;
        }

        .dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          opacity: 0;
          display: flex;
          width: 100%;
          overflow: hidden;
          flex-direction: column;
          transition: opacity 0.3s ease-in-out;
          font-size: 16px !important;

          li {
            a {
              font-size: 16px !important;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: var(--spacing-2) 0;
              color: var(--whiteSecondary);

              &:hover {
                color: var(--white);

                svg {
                  color: var(--white);
                }
              }

              span {
                font-size: 16px !important;
              }

              svg {
                color: var(--whiteSecondary);
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .arrow-open {
          transform: rotate(180deg);
        }

        .dropdown-open {
          opacity: 1;
        }

        img {
          margin-left: var(--spacing-2);
          width: 16px;
        }
      }
    }
  }
}