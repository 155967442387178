:root {
  // Colors
  --primary: #7b2cbf;
  --secondary: #9750d4;
  --backgroundColor: #080709;
  --white: #fff;
  --whiteSecondary: rgba(245, 245, 245, 0.502);

  // Spacing
  --spacing-base: 5px;
  --spacing-2: 10px;
  --spacing-3: 15px;
  --spacing-4: 20px;
  --spacing-5: 25px;
  --spacing-6: 30px;
  --spacing-7: 35px;
  --spacing-8: 40px;

  // Responsive
  --responsive-just-mobile: '(max-width: 768px)';
  --responsive-tablet: '(min-width: 768px) and (max-width: 1025px)';
  --responsive-desktop: '(min-width: 980px)';

  // Fonts
  --font-primary: 'SF Pro Display', sans-serif;
}

// Font-Size
@mixin Font($mobile-size, $desktop-size) {
  font-size: $desktop-size;

  @media (max-width: 768px) {
    font-size: $mobile-size;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    font-size: $mobile-size;
  }
}

@mixin breakpoint($point) {
  @if $point == mobile {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 768px) and (max-width: 1025px) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

// General styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  font-family: var(--font-primary);
  -webkit-tap-highlight-color: transparent;
}
