@import '../../../styles/variables.scss';

.button {
  background: linear-gradient(121deg, var(--secondary) 7%, var(--primary) 50%, var(--primary) 57%, var(--secondary) 100%) padding-box,
    linear-gradient(165deg, #edd8ff 25%, var(--primary) 40%) border-box;
  background-size: 200% 100%;
  box-shadow: -9px 14px 84.5px 0px #7b2cbf80;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 100px;
  white-space: nowrap;
  color: var(--white);
  cursor: pointer;
  user-select: none;
  transition: background-position 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  @include breakpoint(desktop) {
    padding: var(--spacing-4) calc(var(--spacing-5) * 2);
  }

  @include breakpoint(mobile) {
    padding: var(--spacing-3) var(--spacing-7);
  }

  @include breakpoint(tablet) {
    padding: var(--spacing-3) var(--spacing-7);
  }

  &:hover {
    background-position: 100% 0;
    box-shadow: -9px 14px 84.5px 0px #7b2cbf;
  }
}