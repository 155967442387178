@import '../../styles/variables.scss';

footer {
  position: relative;
  padding: 90px 0;

  @media (max-width: 1218px) {
    padding: 90px 20px;
  }

  @include breakpoint(mobile) {
    padding-bottom: 58px;
    padding-top: 58px;
  }

  @include breakpoint(tablet) {
    padding-bottom: 58px;
    padding-top: 32px;
  }

  .content {
    max-width: 1178px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
      flex-direction: column;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .links {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: min-content;
      z-index: 4;

      @include breakpoint(mobile) {
        order: 3;
        margin-top: var(--spacing-4);
      }

      @include breakpoint(tablet) {
        order: 3;
        margin-top: var(--spacing-4);
      }

      .item {
        list-style: none;

        &:first-child {
          margin-right: 40px;
        }

        .link {
          text-decoration: none;
          color: white;
          white-space: nowrap;
          @include Font(16px, 18px);
          transition: color 0.1s ease-in-out;

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .logo {
      z-index: 4;
      margin: 0 auto;

      @include breakpoint(tablet) {
        width: 100%;
      }

      @include breakpoint(mobile) {
        width: 100%;
      }

      img {
        width: 75px;
        margin: 0 auto;
        display: block;
      }

      .textMobile {
        @include Font(14px, 18px);
        z-index: 4;
        grid-row: 2/3;
        grid-column: 2/3;
        text-align: center;
        width: max-content;
        align-self: flex-start;
        color: rgba(255, 255, 255, 0.3);
        margin-top: 26px;
        color: rgba(255, 255, 255, 0.30);
        font-weight: 400;
        line-height: 122.5%;
        margin: 0 auto;
        margin-top: 32px;
        width: 100%;
        max-width: 510px;

        @include breakpoint(desktop) {
          display: none;
        }
      }
    }

    .external-links {
      flex: 1;
      z-index: 4;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: 35px;

      @include breakpoint(mobile) {
        order: 4;
        margin-top: calc(var(--spacing-8) * 2);
        margin-bottom: var(--spacing-8);
      }

      @include breakpoint(tablet) {
        order: 4;
        margin-top: calc(var(--spacing-8) * 2);
        margin-bottom: var(--spacing-8);
      }

      a {
        width: 30px;
        color: var(--white);
        transition: color 0.1s ease-in-out;

        &:hover {
          color: var(--primary);
        }

        @include breakpoint(mobile) {
          width: 24px;
        }

        svg {
          width: 100%;
        }
      }
    }
  }

  .text {
    @include Font(14px, 18px);
    z-index: 4;
    grid-row: 2/3;
    grid-column: 2/3;
    text-align: center;
    width: max-content;
    align-self: flex-start;
    color: rgba(255, 255, 255, 0.3);
    margin-top: 26px;
    color: rgba(255, 255, 255, 0.30);
    font-weight: 400;
    line-height: 122.5%;
    margin: 0 auto;
    margin-top: 32px;

    @include breakpoint(mobile) {
      display: none;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  .light {
    width: 474px;
    height: 326px;
    position: absolute;
    left: 50%;
    bottom: -315px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: #8900FF;
    mix-blend-mode: screen;
    filter: blur(150px);
    z-index: 0;
    pointer-events: none;
  }

  .lightFill {
    width: 930px;
    height: 638px;
    position: absolute;
    left: 50%;
    bottom: -286px;
    transform: translateX(-50%);
    border-radius: 930px;
    background: rgba(123, 44, 191, 0.25);
    filter: blur(150px);
    z-index: 0;
    pointer-events: none;
  }

  .left {
    position: absolute;
    z-index: 1;
    bottom: -100px;
    left: -200px;

    @include breakpoint(mobile) {
      bottom: 60px;
      height: 130px;
    }

    @include breakpoint(tablet) {
      bottom: 70px;
      height: 160px;
    }
  }

  .right {
    position: absolute;
    z-index: 1;
    bottom: -30px;
    right: -200px;

    @include breakpoint(mobile) {
      height: 130px;
      bottom: -20px;
    }

    @include breakpoint(tablet) {
      height: 160px;
      bottom: -20px;
    }
  }
}